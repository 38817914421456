import React from 'react'
import { IonGrid, IonRow, IonCol, IonRefresher, IonRefresherContent, IonContent } from '@ionic/react'
import { withTranslation } from '../../lib/translate'
import moment from '../../lib/moment'

const doRefresh = (event) => {
	// eslint-disable-next-line no-console
	console.log('Begin async operation')

	setTimeout(() => {
		// eslint-disable-next-line no-console
		console.log('Async operation has ended')
		event.detail.complete()
	}, 2000)
}

const Redeem = ({ __, transactionHistory }) => {
	return (
		<IonContent className="history-holder" color="light">
			<div className="history-content">
				<IonRefresher slot="fixed" onIonRefresh={ doRefresh }>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				{ (transactionHistory || []).length === 0 ?
					<p>{ __('No data') }</p>
					:
					<IonGrid>
						{
							transactionHistory.map((i, index) => {
								const { stamp_power, transaction_date } = i
								return (
									<IonRow key={index} className="history-item">
										<IonCol>
											<p>{ moment(transaction_date).format('ddd DD MMMM YYYY') }</p>
											<h2>{ __('Redeemed')}</h2>
											{/* <p>East Dulwich / 17:57</p> */}
										</IonCol>
										<IonCol>
											<p>+{stamp_power} { __('points')}</p>
										</IonCol>
									</IonRow>
								)
							})
						}
					</IonGrid>
				}
			</div>
		</IonContent>
	)
}

export default withTranslation(Redeem)
