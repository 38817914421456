import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Capacitor } from '@capacitor/core'
import { IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonMenuToggle, IonButtons, IonButton, IonIcon, IonLabel, IonFooter, IonAlert, isPlatform } from '@ionic/react'
import appConfig from '../../appConfig.js'
import navConfig from '../../navConfig.js'
import { logout } from '../../store/actions'
import { getDefaultRoute, forwardTo, padNumber } from '../../lib/utils'
import './index.css'
import packageJson from '../../../package.json';
import { withTranslation } from '../../lib/translate'
import { Device } from '@capacitor/device'

const NavItem = ({ __, item, hideIcon, handleLogout }) => {
	return (
		<IonMenuToggle key={ item.path } auto-hide='false'>
			<IonItem className='nav-item' color='primary' button onClick={() => item.fn === 'logout' ? handleLogout() : forwardTo(item.path)}>
				{ hideIcon ? null : <IonIcon className='nav-icon' slot='start' icon={ item.icon }/> }
				<IonLabel className='nav-label'>{__(item.label)}</IonLabel>
			</IonItem>
		</IonMenuToggle>
	)
}

class Drawer extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			appDeviceVersion: '',
			showPopup: false
		}
		this.handleLogout = this.handleLogout.bind(this)
		this.handleModal = this.handleModal.bind(this)
	}

	handleLogout() {
		this.props.dispatch(logout())
		const defaultRoute = getDefaultRoute()
		forwardTo(defaultRoute.path)
	}

	handleModal(val) {
		this.setState({ showPopup: val })
	}

	async componentDidMount () {
		const info = await Device.getInfo()
		this.setState({ appDeviceVersion: info.appVersion })
	}

	addZeros = (arr = []) => arr.map((i, index) => {
		// e.g. 1.23.8
		// skip first number (app version) (e.g. 1)
		// add zeros only to patch (e.g. 23) or minor (e.g. 8)
		if (index !== 0) {
			return padNumber(i, 3)
		}
		return i
	})

	isAppVersionValid = (apiVersion = '', appVersion = '') => {
		let ret = true
		if (apiVersion && appVersion && apiVersion !== '' && appVersion !== '') {
			const apiVersionInt = parseInt(this.addZeros(apiVersion.split('.')).join(''), 10)
			const appVersionInt = parseInt(this.addZeros(appVersion.split('.')).join(''), 10)
			ret = appVersionInt >= apiVersionInt
			// eslint-disable-next-line no-console
			console.log(
				'APP VERSION:' +
				'\n    isValid:    ' + ret +
				'\n    platform:   ' + (Capacitor.getPlatform() !== 'web' ? 'MOBILE' : 'WEB') +
				'\n    device:     (' + typeof appVersion + ')-> ' + appVersion + ' (int: ' + appVersionInt + ')' +
				'\n    apiversion: (' + typeof apiVersion + ')-> ' + apiVersion + ' (int: ' + apiVersionInt + ')')
		} else {
			// eslint-disable-next-line no-console
			console.error('Skip version checking.')
		}
		return ret
	}

	componentDidUpdate(prevProps){
		const { appDeviceVersion } = this.state
		if (this.props.appVersion !== prevProps.appVersion) {
		if (!this.isAppVersionValid(this.props.appVersion,  packageJson.version) &&  packageJson.version !== '') {
			this.handleModal(true)
		}
		}
	}

	render (){
		const { history, auth, __ } = this.props
		const { showPopup, appDeviceVersion } = this.state
		const { loggedIn } = auth
		return (
			<IonMenu className='drawer-menu' side='start' type='overlay' contentId='main'>
				<IonHeader color='primary'>
					<IonToolbar color='primary'>
						<IonTitle>{ appConfig.theme.nav.label }</IonTitle>
						<IonButtons slot='end'>
							<IonMenuToggle>
								<IonButton button clear>
									<IonIcon slot='icon-only' icon='close'/>
								</IonButton>
							</IonMenuToggle>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent color='primary'>
					<div className='contrast-separator contrast-separator-mini'/>
					<IonList lines='none'>
						{ navConfig.routes.filter(route => !!route.path).map(item =>
							<NavItem key={ item.path } __={__} history={ history } item={ item }/>
						)}
					</IonList>
					<IonList lines='none'>
						<NavItem history={ history } __={__} handleLogout={ this.handleLogout } item={ navConfig.authRoutes.find(item => item.fn === (loggedIn ? 'logout' : 'login'))}/>
					</IonList>
					<div className='contrast-separator contrast-separator-mini'/>
					<IonList lines='none'>
						{ navConfig.additionalRoutes.filter(route => !!route.path).map(item =>
							<NavItem key={ item.path } __={__} history={ history } item={ item } hideIcon={ true }/>
						)}
					</IonList>
				</IonContent>
				<IonFooter mode='ios' color='primary'>
					<IonToolbar color='primary' className={isPlatform('android') ? 'app-android-version' : 'app-ios-version'}>
						<IonLabel className={ isPlatform('ios') ? 'title' : 'title-andorid'} size='small' slot='start' color='white'>
							v{ packageJson.version }
						</IonLabel>
						<a href="https://5loyalty.com" target="_blank" rel="noopener noreferrer">
							<div className="logo-5l"><div/></div>
						</a>
					</IonToolbar>
				</IonFooter>
				<IonAlert
					isOpen={ showPopup }
					onDidDismiss={ () => this.handleModal(false)}
					header={ __('App version') }
					message={ __('Your app is out of date. Please update.') }
					buttons={[
						{
							text: __('OK'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.handleModal(false)
						}
					]}
				/>
			</IonMenu>
		)
	}
}

const stateToProps = state => {
	const { auth } = state.profile
	const { appVersion } = state.common
	return {
		auth,
		appVersion
	}
}

export default connect(stateToProps)(withRouter(withTranslation(Drawer)))
