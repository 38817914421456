import { SET_ORDERS_PROP } from './constants'
import { isDefined } from '../../lib/utils'

// The initial application state
let initialState = {
	history: []
}

function reducer(state = initialState, action) {
	switch (action.type) {
	case SET_ORDERS_PROP:
		return { ...state, [action.key]: isDefined(action.merge) && action.merge ? {...state[action.key], ...action.value} : action.value }
	default:
		return state
	}
}

export default reducer
