import axios from 'axios'
import asyncStorage from './asyncStorage'
import { isDefined } from './utils'
import appConfig from '../appConfig'

let axiosInstance

const baseURL = appConfig.api.baseURL
const endpoints = {
	login: '/api-token-auth',//post
	register: '/me/register',//post
	getProfile: '/me',//get
	changePassword: '/me',//put
	changeProfile: '/me',//put
	resetPassword: '/me/reset_password',//put
	deleteCard: '/delete_payment_card',//put
	voucherCode: '/voucher/code',//put
	referFriend: '/refer_friend',//post
	sendFeedback: '/feedback/create',//post,
	firebaseToken: '/firebase-token',//post,
	appVersion: '/appversion',//get,
	social: '/social',//get,
	restaurants: '/restaurants',//get
	terms: '/terms',//get,
	privacyPolicy: '/privacy',//get
	rewards: '/reward_milestones',//get
	history: '/transactions/history',//get
	validateEmail: '/me/validate_email', //get
	removeProfile: '/me/delete_user' // post
}

const createAxiosInstance = token => {
	const headers = isDefined(token) ? {
		Authorization: 'JWT ' + token
	} : {}
	axiosInstance = axios.create({
		headers,
		timeout: 15000
		// responseType: 'json'
	})
	return axiosInstance
}


(async () => {
	const existingToken = await asyncStorage.getItem('token')
	axiosInstance = createAxiosInstance(existingToken)
})()

export const isFieldValid = (fieldName, errors = {}) => !isDefined(errors[fieldName])

export const getErrorMessage = (fieldName, errors = {}) => !isFieldValid(fieldName, errors) ? errors[fieldName] : ''

const api = {
	login: async (username, password) => {
		const response = await createAxiosInstance().post(baseURL + endpoints.login, { username, password })
		axiosInstance = createAxiosInstance(response.data.token)
		return response
	},
	register: async userData => {
		const response = await createAxiosInstance().post(baseURL + endpoints.register, userData)
		axiosInstance = createAxiosInstance(response.data.token)
		return response
	},
	logout: () => {
		// eslint-disable-next-line no-console
		console.log('logout')
	},
	resetPassword: async email => await axiosInstance.put(baseURL + endpoints.resetPassword, {email}),
	getProfile: async () => await axiosInstance.get(baseURL + endpoints.getProfile),
	updateProfile: async (data={}) => await axiosInstance.put(baseURL + endpoints.changeProfile, data),
	sendFirebaseToken: async data => await axiosInstance.post(baseURL + endpoints.firebaseToken, data),
	getAppVersion: () => axiosInstance.get(baseURL + endpoints.appVersion).then(res => res.data.app_version),
	getSocialLinks: () => axiosInstance.get(baseURL + endpoints.social).then(res => res.data.social_link_json),
	sendFeedback: async (data={}) => await axiosInstance.post(baseURL + endpoints.sendFeedback, data),
	getRestaurants: () => axiosInstance.get(baseURL + endpoints.restaurants).then(res => res.data.data),
	getTerms: () => axiosInstance.get(baseURL + endpoints.terms).then(res => res.data),
	getPrivacyPolicy: () => axiosInstance.get(baseURL + endpoints.privacyPolicy).then(res => res.data),
	getRewards: () => axiosInstance.get(baseURL + endpoints.rewards).then(res => res.data.data),
	getHistory: () => axiosInstance.get(baseURL + endpoints.history).then(res => res.data),
	validateEmail: async () => await axiosInstance.get(baseURL + endpoints.validateEmail),
	removeProfile: async (data) => await axiosInstance.post(baseURL + endpoints.removeProfile, data),
}

api.createAxiosInstance = createAxiosInstance

export default { ...api }
