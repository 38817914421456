import React from 'react'
import { IonTitle, IonToolbar, IonButtons } from '@ionic/react'
import BackButton from '../backButton'
import './index.css'

const Header = ({ title, hideBack }) => {
	return (
		<IonToolbar className="dynamic-header">
			{ !hideBack ?
				<IonButtons slot="start">
					<BackButton/>
				</IonButtons>
				: null }
			<IonTitle className="ion-text-center">{ title }</IonTitle>
			{ !hideBack ?
				<IonButtons slot="end">
					<BackButton style={{ visibility: 'hidden' }}/>
				</IonButtons>
				: null }
		</IonToolbar>
	)
}

export default Header
