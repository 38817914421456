import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonItem,	IonLabel, IonList, IonGrid, IonCol, IonRow, IonContent } from '@ionic/react'
import { arrowBack, arrowForward } from 'ionicons/icons'
import { Map, GoogleApiWrapper, Marker } from '../googleMapsReact'
import appConfig from '../../appConfig.js'
import { withTranslation } from '../../lib/translate'
import { isDefined } from '../../lib/utils'
import './index.css'
import Loading from '../spinner'

export class MapContainer extends Component {
	constructor(props) {
		super(props)

		this.state = {
			stores: [],
			map: null,
			locationName: 'Location',
			locationId: 0,
			locationHolderNested: false
		}
	}

	componentDidMount() {
		let locations = []
		this.props.restaurants.forEach(restaurant => {
			const position = this.parseLocation(restaurant.position)
			locations.push({
				latitude: position.lat,
				longitude: position.lng,
				...restaurant
			})
		})
		this.setState({stores: locations})
	}

	onMapReady = (mapProps, map) => {
		this.setState({ map })
	}

	displayMarkers = () => {
		return (this.state.stores || {}).map((store, index) => {
			return <Marker key={ index } id={ index } position={{
				lat: store.latitude,
				lng: store.longitude
			}}
			icon={ index === this.state.locationId ? { url: require('../../assets/images/marker-blue.png') } : { url: require('../../assets/images/marker-red.png')}} // source http://icon-park.com/icon/simple-location-map-pin-icon-navy-blue-free-vector-data/
			onClick={ this.onMarkerClick }
			name={ store.name} />
		})
	}

	onMarkerClick = (props, marker) => {
		this.setState({
			activeMarker: marker,
			selectedPlace: props,
			locationId: props.id
		})
		const lat = parseFloat(props.position.lat)
		const lng = parseFloat(props.position.lng)
		this.state.map.panTo({ lat, lng })
	}

	changeMarker = direction => {
		let nextId
		if (direction === 'next') {
			if (this.state.locationId === this.state.stores.length - 1) {
				nextId = 0
			} else {
				nextId = this.state.locationId + 1
			}
		} else {
			if (this.state.locationId === 0) {
				nextId = this.state.stores.length - 1
			} else {
				nextId = this.state.locationId - 1
			}
		}
		const lat = parseFloat(this.state.stores[nextId].latitude)
		const lng = parseFloat(this.state.stores[nextId].longitude)
		if (lat && lng){
			this.state.map.panTo({ lat, lng })
		} else {
			let lat = appConfig.services.google_maps.defaultLat
			let lng = appConfig.services.google_maps.defaultLng
			this.state.map.panTo({ lat, lng })
		}

		this.setState({ locationId: nextId }, () => this.displayMarkers())
	}

	parseLocation = (location = '') => {
		if (isDefined(location) && location !== ''){
			let position = location.split(',')
			return {
				lat: position[0],
				lng: position[1]
			}
		}

		return {}
	}

	nestLocationLayer = () => {
		if (document){
			const mapTG = document.querySelector('.gm-style')
			if (mapTG){
				const locationHolder = document.querySelector('.map-location-holder')
				mapTG.appendChild(locationHolder)
			}
		}
	}

	mapBoundsChanged = () => {
		if (!this.state.locationHolderNested){
			this.setState({
				locationHolderNested: true
			})
			this.nestLocationLayer()
		}
	}

	render() {
		const { __ } = this.props
		const { stores } = this.state
		let selectedRestaurant = {}
		if (isDefined(this.state.locationId) && isDefined(this.state.stores[this.state.locationId])){
			selectedRestaurant = this.state.stores[this.state.locationId]
		}
		return (
			<Loading additionalLoadingCondition={ isDefined(stores) && stores.length === 0 }>
				<div>
					<Map
						google={ this.props.google }
						zoom={ 7 }
						className="map"
						initialCenter={{ lat: selectedRestaurant.latitude || 0, lng: selectedRestaurant.longitude || 0 }}
						streetViewControlOptions={{ position: 7 }}
						zoomControlOptions={{ position: 7 }}
						onBounds_changed={ this.mapBoundsChanged }
						onReady={ this.onMapReady }>
						{ this.displayMarkers()}
					</Map>
					<div className="map-location-holder">
						<IonToolbar color="primary" style={{ borderRadius: 5 }}>
							<IonButtons slot="start">
								<IonButton button clear onClick={() => this.changeMarker('prev')}>
									<IonIcon slot="icon-only" icon={ arrowBack } />
								</IonButton>
							</IonButtons>
							<IonTitle className="ion-text-center">{ selectedRestaurant.name}</IonTitle>
							<IonButtons slot="end">
								<IonButton button clear onClick={() => this.changeMarker('next')}>
									<IonIcon slot="icon-only" icon={ arrowForward } />
								</IonButton>
							</IonButtons>
						</IonToolbar>
						<IonContent keyscrollEvents={ true }>
							<IonList className="h-padded location-section">
								{selectedRestaurant.json_opening_times ?
									<IonItem>
										<IonGrid>
											<IonRow>
												<IonCol>
													<IonLabel>{ __('Opening Times')}</IonLabel>
												</IonCol>
											</IonRow>
											<IonRow>
												<div className="location-info">
													{(selectedRestaurant.json_opening_times || []).map((item, index) => {
														return (
															<div key={index} className= { 'time time-' + index }>
																<div className="day">{ __(item.day)}:</div>
																<div>{item.open_time} - {item.close_time}</div>
															</div>
														)
													})}
												</div>
											</IonRow>
										</IonGrid>
									</IonItem> : null
								}
								{selectedRestaurant.address ?
									<IonItem>
										<IonLabel>
											<h2>{ __('Address')}</h2>
											<p>{selectedRestaurant.address}</p>
										</IonLabel>
									</IonItem> : null
								}
								{selectedRestaurant.telephone ?
									<IonItem>
										<IonLabel>
											<h2>{ __('Telephone Number')}</h2>
											<p>{selectedRestaurant.telephone}</p>
										</IonLabel>
									</IonItem> : null
								}
								{selectedRestaurant.email ?
									<IonItem>
										<IonLabel>
											<h2>{ __('Email Address')}</h2>
											<p>{selectedRestaurant.email}</p>
										</IonLabel>
									</IonItem> : null
								}
							</IonList>
						</IonContent>
					</div>
				</div>
			</Loading>
		)
	}
}


const stateToProps = state => {
	const { restaurants } = state.restaurants
	return {
		restaurants: restaurants || []
	}
}


export default GoogleApiWrapper({
	apiKey: appConfig.services.google_maps.google_api_key
})(withRouter(withTranslation(connect(stateToProps)(MapContainer))))
