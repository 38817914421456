import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { IonApp, IonSplitPane, IonPage, IonAlert } from '@ionic/react'
import { Router } from 'react-router-dom'
import appConfig from './appConfig'
import navConfig from './navConfig'
import ProtectedRoute from './components/protectedRoute'
import history from './history'
import { getDefaultRoute } from './lib/utils'
import { init, getRestaurants, changeConnectionStatus, getProfile, setCommonModal, updateProfile } from './store/actions'
import { withTranslation } from '../src/lib/translate'
import ValidateModal from './components/validateModal'
import StaticHeader from './components/staticHeader'
import Drawer from './components/drawer'
import Toast from './components/toast'
import * as firebase from 'firebase'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme */
import './theme/index.css'
import { Network } from '@capacitor/network'
import { App as AppCap } from '@capacitor/app'


AppCap.addListener('backButton', () => {
	if (
	  history.location &&
	  history.location.pathname &&
	  getConfig().general.appExitRoutes.indexOf(history.location.pathname) !== -1
	) {
	  AppCap.exitApp();
	} else {
	}
  });

const mapRoutes = (routes, extraProps = {}) => {
	return routes.filter(route => !!route.path && !!route.component).map(item => {
		const { path } = item
		const ActualRoute = item.protected ? ProtectedRoute : Route
		return (
			<ActualRoute exact={ item.exact } path={ path } key={ 'nav-key-' + path } route={ item }
				cmp={ item.component }
				render={ props =>
					item.render ?
						item.render({ ...props, ...extraProps, route: item })
						:
						<item.component { ...props } { ...extraProps } route={ item } />
				}
			/>
		)
	})
}

class App extends React.Component {
	constructor(props) {
		super(props)
		this.app = firebase.initializeApp(appConfig.firebaseConfig)
	}
	componentDidMount(){
		this.props.dispatch(init())
		this.props.dispatch(getRestaurants())

		Network.addListener('networkStatusChange', conStatus => {
			const status = conStatus.connected
			this.props.dispatch(changeConnectionStatus(status))
		})
	}

	componentDidUpdate(prevProps) {
		const { profile, dispatch } = this.props
		if (prevProps.profile.id !== profile.id && profile.id) {
			this.db = this.app.database().ref(profile.id)
			this.db.on('value', (snap) => {
				let db_record = snap.val()
				if (db_record) {
					dispatch(getProfile(true))
				}
			})
		}
	}

	onRemoveValidateModal = () => {
		const { dispatch } = this.props
		dispatch(setCommonModal('isValidationModalOpen', false))
		dispatch(updateProfile({ is_verification_pop_up_shown: true }, true))
	}


	render (){
		const { __, isValidationModalOpen } = this.props
		const defaultRoute = getDefaultRoute()
		const routeComponents = mapRoutes(navConfig.routes)
		const authRouteComponents = mapRoutes(navConfig.authRoutes)
		const additionalRouteComponents = mapRoutes(navConfig.additionalRoutes)
		const notInMenuRouteComponents = mapRoutes(navConfig.notInMenuRoutes)

		return (
			<IonApp>
				<Router history={ history }>
					<IonSplitPane contentId="main">
						<Drawer/>
						<IonPage id="main">
							<StaticHeader/>
							<Switch>
								{ routeComponents }
								{ authRouteComponents }
								{ additionalRouteComponents }
								{ notInMenuRouteComponents }
								{ defaultRoute ?
									<Route exact path="/" render={ () => <Redirect to={ defaultRoute.path } /> } />
									: null }
							</Switch>
							<Toast />
							<ValidateModal/>
							<IonAlert
								isOpen={ isValidationModalOpen }
								onDidDismiss={this.onRemoveValidateModal}
								header={ __('Success') }
								message={ __('The app is now unlocked to redeem your loyalty') }
								buttons={[
									{
										text: __('Close'),
										role: 'cancel',
										cssClass: 'secondary',
										handler: this.onRemoveValidateModal
									}
								]}
							/>
						</IonPage>
					</IonSplitPane>
				</Router>
			</IonApp>
		)
	}
}

const stateToProps = state => {
	const { auth, profile } = state.profile
	const { isValidationModalOpen } = state.common
	return {
		auth,
		profile,
		isValidationModalOpen
	}
}

export default connect(stateToProps)(withTranslation(App))
