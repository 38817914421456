import React from 'react'
import { withRouter } from 'react-router'
import { IonHeader, IonToolbar, IonMenuButton, IonButtons, IonButton, IonIcon } from '@ionic/react'
import { forwardTo } from '../../lib/utils'
import { qrCode } from 'ionicons/icons'

import './index.css'

const StaticHeader = ({ history }) => {
	const currentPath = history.location.pathname
	return (
		<>
			{ currentPath !== '/login' ?
				<IonHeader className="static">
					{ currentPath !== '/register' ?
						<IonToolbar className="primary-toolbar">
							<IonButtons slot="start">
								<IonMenuButton color="white"/>
							</IonButtons>
							<IonButtons color="primary">
								<IonButton className='image-button' color="primary-shade" onClick={() => { forwardTo('/dashboard')}}/>
							</IonButtons>
							<IonButtons slot="end">
								<IonButton button clear onClick={() => forwardTo('/loyalty', { tab: 'scan' })}><IonIcon slot="icon-only" color='white' icon={ qrCode }/></IonButton>
							</IonButtons>
						</IonToolbar>
						: null }
					<IonToolbar color={ 'primary' + (currentPath !== '/register' ? '-shade' : '')}>{ currentPath === '/register' ? <p><br/><br/></p> : null }</IonToolbar>
				</IonHeader>
				: null
			}
		</>
	)
}

export default withRouter(StaticHeader)
