import { home, trophy, person, mail, pin, fileTrayFull, heart, logIn, logOut } from 'ionicons/icons'
import Dashboard from './screens/dashboard'
import Loyalty from './screens/loyalty'
import Account from './screens/account'
import Feedback from './screens/feedback'
import History from './screens/history'
import Locations from './screens/locations'
import Social from './screens/social'
import Login from './screens/login'
import Register from './screens/register'
import ResetPassword from './screens/resetPassword'
import Terms from './screens/terms'
import Privacy from './screens/privacy'

const navConfig = {
	routes: [
		{ label: 'Home', path: '/dashboard', component: Dashboard, icon: home, exact: true, default: true },
		{ label: 'Loyalty', path: '/loyalty', component: Loyalty, icon: trophy, protected: true },
		{ label: 'My Account', path: '/account', component: Account, icon: person, protected: true },
		{ label: 'Leave Feedback', path: '/feedback', component: Feedback, icon: mail, protected: true },
		{ label: 'History', path: '/history', component: History, icon: fileTrayFull, protected: true },
		{ label: 'Locations', path: '/locations', component: Locations, icon: pin, protected: false },
		{ label: 'Social Media', path: '/social', component: Social, icon: heart, protected: false }
	],
	authRoutes: [
		{ label: 'Login', path: '/login', component: Login, icon: logIn, fn: 'login' },
		{ label: 'Logout', path: '/logout', icon: logOut, fn: 'logout' }
	],
	additionalRoutes: [
		{ label: 'Terms & Conditions', path: '/terms', component: Terms },
		{ label: 'Privacy Policy', path: '/privacy', component: Privacy }
	],
	notInMenuRoutes: [
		{ path: '/register', component: Register },
		{ path: '/reset-password', component: ResetPassword }
	]
}

export default navConfig
