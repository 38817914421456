import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IonButton, IonItem, IonLabel,	IonInput, IonList, IonCheckbox,	IonNote, IonAlert, IonAvatar } from '@ionic/react'
import S3 from 'aws-s3'

import appConfig from '../../appConfig'
import Layout from '../../components/layout'
import { FieldError } from '../../components/common'
import { validateForm, isDefined } from '../../lib/utils'
import moment from '../../lib/moment'
import { withTranslation } from '../../lib/translate'
import { updateProfile, setModal, loading, deleteUser } from '../../store/actions'
import { beforeShowTimePicker, beforeCloseTimePicker } from '../../store/restaurants/actions'
// import moment from 'moment'
import Loading from '../../components/spinner'

import './index.css'
import defaultImg from '../../assets/images/no_avatar.png'
import Mobiscroll from '../../components/mobiscroll'

const { configS3 } = appConfig
const S3Client = new S3(configS3)

const { DatePicker } = Mobiscroll

class Account extends Component {
	constructor(props){
		super(props)
		this.state = {
			first_name: this.props.profile.first_name || '',
			last_name: this.props.profile.last_name || '',
			email: this.props.profile.email || '',
			mobile: this.props.profile.mobile || '',
			birthday: this.props.profile.birthday || '',
			profile_image_url: this.props.profile.profile_image_url || defaultImg,
			imageFile: null,
			is_subscribed: this.props.profile.is_subscribed || false,
			formErrors: {},
			closeMyAccount: false
		}
		this.handleInput = this.handleInput.bind(this)
		this.handleSave = this.handleSave.bind(this)
		this.formConfig = {
			email: { type: 'email', required: false },
			mobile: { type: 'tel', required: false }
		}
		this.triggerInputFile = this.triggerInputFile.bind(this)
		this.inputRef = React.createRef()
		this.onChangeFile = this.onChangeFile.bind(this)
	}

	componentDidUpdate(prevProps) {
		if (this.props.profile.first_name !== prevProps.profile.first_name ){
			this.setState({ first_name: this.props.profile.first_name })
		}
		if (this.props.profile.last_name !== prevProps.profile.last_name){
			this.setState({ last_name: this.props.profile.last_name })
		}
		if (this.props.profile.email !== prevProps.profile.email){
			this.setState({ email: this.props.profile.email })
		}
		if (this.props.profile.mobile !== prevProps.profile.mobile){
			this.setState({ mobile: this.props.profile.mobile })
		}
		if (this.props.profile.birthday !== prevProps.profile.birthday){
			this.setState({ birthday: this.props.profile.birthday ? moment(this.props.profile.birthday).format('MM/DD/YY') : null })
		}
		if (this.props.profile.profile_image_url !== prevProps.profile.profile_image_url){
			this.setState({ profile_image_url: this.props.profile.profile_image_url })
		}
		if (this.props.profile.is_subscribed !== prevProps.profile.is_subscribed){
			this.setState({ is_subscribed: this.props.profile.is_subscribed })
		}
	}

	handleInput = (key, val) => this.setState({ [key]: val })

	// async takePicture() {
	// 	await Camera.getPhoto({
	// 		quality: 100,
	// 		allowEditing: false,
	// 		resultType: CameraResultType.DataUrl,
	// 		source: CameraSource.Prompt
	// 	}).then(imageData => {
	// 		this.setState({
	// 			imageFile: imageData.dataUrl,
	// 			extension: imageData.format,
	// 			profile_image_url: imageData.dataUrl
	// 		})
	// 	})
	// }

	handleSave () {
		let formErrors = validateForm(this.formConfig, this.state)
		this.setState({ formErrors })
		if (Object.keys(formErrors).length === 0) {
			const { first_name, last_name, email, mobile, birthday, profile_image_url, imageFile, is_subscribed } = this.state
			const profile = {
				first_name: first_name,
				last_name: last_name,
				email: email,
				mobile: mobile,
				birthday: birthday,
				profile_image_url: profile_image_url,
				is_subscribed
			}

			if (!isDefined(imageFile)) {
				this.props.dispatch(updateProfile(profile))
			} else {
				const imageName = configS3.imageNamePrefix + this.props.profile.id + '.' + this.state.extension
				this.props.dispatch(loading(true))
				fetch(this.state.imageFile).then(res => res.blob()).then(blob => {
					const file = new window.File([blob], imageName)
					S3Client.uploadFile(file, imageName).then(data => {
						profile.profile_image_url = data.location
						this.props.dispatch(updateProfile(profile))
						this.setState({imageFile: null})
						this.props.dispatch(loading(false))
					}).catch(() => {
						this.props.dispatch(loading(false))
					})
				})
			}
		}
	}

	triggerInputFile () {
		// this.takePicture()
	}

	onChangeFile(event){
		event.stopPropagation()
		event.preventDefault()
		const imageFile = event.target.files[0]
		let reader = new window.FileReader()
		reader.readAsDataURL(imageFile)
		reader.onloadend = () => {
			this.setState({
				imageFile,
				profile_image_url: reader.result
			})
		}
	}

	render() {
		const { __, isProfileModalOpen, isShowTimePicker, profile } = this.props
		const { first_name, last_name, email, mobile, birthday, formErrors, profile_image_url, is_subscribed, closeMyAccount } = this.state
		const newClass = isShowTimePicker ? 'red' : 'gray'
		return (
			<Loading transparent>
				<Layout color="white" headerTitle="My Account">
					<div className="avatar-box">
						<IonAvatar className="profile-image-content" onClick={ this.triggerInputFile }>
							<img alt="" src={ profile_image_url } />
						</IonAvatar>
					</div>
					<input type="file" className="input-avatar" ref={ this.inputRef } onChange={ e => this.onChangeFile(e) }/>
					<IonList>
						<IonItem>
							<IonLabel position="floating">{ __('First Name') }</IonLabel>
							<IonInput onIonChange={ e => this.handleInput('first_name', e.target.value) } clearInput required={ true } type="text" pattern="text" inputmode="text" value={ first_name }>
							</IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">{ __('Last Name') }</IonLabel>
							<IonInput onIonChange={ e => this.handleInput('last_name', e.target.value) } clearInput required={ true } type="text" pattern="text" inputmode="text" value={ last_name }>
							</IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">{ __('Email') }</IonLabel>
							<IonInput onIonChange={ e => this.handleInput('email', e.target.value) } clearInput required={ true } type="email" pattern="email" inputmode="email" value={ email }></IonInput>
							{formErrors.email ? <FieldError className="field-error" value={ __(formErrors.email) } />: null}
						</IonItem>
						<IonItem>
							<IonLabel position="floating">{ __('Mobile Number') }</IonLabel>
							<IonInput onIonChange={ e => this.handleInput('mobile', e.target.value) } clearInput required={ true } type="tel" pattern="tel" inputmode="tel" value={ mobile }>
							</IonInput>
							{formErrors.mobile ? <FieldError className="field-error" value={ __(formErrors.mobile) } />: null}
						</IonItem>
						{/* <IonItem>
							<IonLabel position="floating">{ __('Date of Birth') }</IonLabel>
							<IonDatetime onIonChange={(e) => this.handleInput('birthday', e.target.value)} clearInput required={ true } displayFormat="MM/DD/YY" value={ birthday }></IonDatetime>
						</IonItem> */}
						<div className="date-picker-wrapper">
							<label className={`date-picker-label date-picker-label--${newClass}`} htmlFor="demo-non-form">{ __('Date of Birth') }</label>
							<DatePicker
								className="data-picker-input"
								display="bottom"
								setText="Done"
								value={ birthday }
								onSet={(e, a) => this.handleInput('birthday', a.element.value)}
								dateFormat="dd-mm-yy"
								onBeforeShow={ () => { this.props.dispatch(beforeShowTimePicker()) }}
								onClose={ () => { this.props.dispatch(beforeCloseTimePicker()) }}
							/>
						</div>
						<h4 className="top-medium">{ __('Communication Preferences') }</h4>

						<IonItem line="none">
							<IonCheckbox color="primary" slot="start" checked={ is_subscribed } onIonChange={ e => this.handleInput('is_subscribed', e.detail.checked) } />
							<IonLabel className="ion-text-wrap">
								<IonNote>{ __('I would like to receive') + ' ' + appConfig.general.clientName + ' ' + __('updates and offers via email')}</IonNote>
							</IonLabel>
						</IonItem>
					</IonList>

					<div className="top-medium">
						<IonButton expand="block" color="primary" onClick={ () => this.handleSave() }>{ __('Save') }</IonButton>
						<IonButton
							expand="block"
							fill="clear"
							color="danger"
							className="underlined"
							onClick={() => this.setState({ closeMyAccount: true })}
						>
							{__('Close my account')}
						</IonButton>
					</div>
				</Layout>
				<IonAlert
					isOpen={closeMyAccount}
					onDidDismiss={() => this.setState({ closeMyAccount: false })}
					header={__('Close Your Account')}
					message={__(
						'Are you sure you wish to close your account? Any points and vouchers you have earned will be permanently lost',
					)}
					buttons={[
						{
						text: __('Cancel'),
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => this.setState({ closeMyAccount: false }),
						},
						{
						text: __('OK'),
						handler: () => this.props.dispatch(deleteUser(profile.user_token)),
						},
					]}
					/>
				<IonAlert
					isOpen={ isProfileModalOpen }
					onDidDismiss={ () => this.props.dispatch(setModal('isProfileModalOpen', false)) }
					header={ __('Success') }
					message={ __('Profile is updated.') }
					buttons={[
						{
							text: __('Close'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.props.dispatch(setModal(('isProfileModalOpen', false)))
						}
					]}
				/>
			</Loading>
		)
	}
}

const stateToProps = (state) => {
	const { auth, profile, isProfileModalOpen } = state.profile
	const { isShowTimePicker } = state.restaurants
	return {
		auth,
		profile,
		isProfileModalOpen,
		isShowTimePicker
	}
}

export default connect(stateToProps)(withTranslation(Account))
