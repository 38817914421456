import React from 'react'
import { IonItem, IonLabel, IonInput, IonButton, IonIcon, isPlatform } from '@ionic/react'
import { connect } from 'react-redux'
import Layout from '../../components/layout'
import PasswordInput from '../../components/passwordInput'
import { forwardTo, getDefaultRoute } from '../../lib/utils'
import { loginRequest } from '../../store/actions'
import { withTranslation } from '../../lib/translate'
import Loading from '../../components/spinner'
import { FieldError } from '../../components/common'
import { validateForm } from '../../lib/utils'
import { lockClosed, mail } from 'ionicons/icons'
import './index.css'

class Login extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			username: '',
			password: '',
			formErrors: {}
		}
		this.handleInput = this.handleInput.bind(this)
		this.handleLogin = this.handleLogin.bind(this)
		this.formConfig = {
			username: { type: 'email', required: true },
			password: { type: 'password', required: true }
		}
	}

	handleInput (key, val) {
		this.setState({[key]: val })
	}

	handleLogin () {
		let formErrors = validateForm(this.formConfig, this.state)
		this.setState({ formErrors })
		if (Object.keys(formErrors).length === 0) {
			const { username, password } = this.state
			this.props.dispatch(loginRequest({ username, password }))
		}
	}

	checkLoginStatus = () => {
		const { loggedIn } = this.props.auth
		if (loggedIn){
			const defaultRoute = getDefaultRoute()
			forwardTo(defaultRoute.path)
		}
	}

	componentDidUpdate() {
		this.checkLoginStatus()
	}

	componentDidMount() {
		this.checkLoginStatus()
	}

	render (){
		const { __ } = this.props
		const { username, password } = this.state
		return (
			<Loading transparent>
				<Layout headerTitle={ __('Login')} blank={ true } poster="login-01" color='white'>
					<h3 className="login-header">{ __('Click below to register or login')}</h3>
					<div className="ion-text-center">
						<IonItem>
							<IonIcon slot='start' className='login-icon' size="small" icon={mail} />
							<IonInput className={isPlatform('android') ? 'android-login-input' : 'login-input'} onIonChange={(e) => this.handleInput('username', e.target.value)} clearInput required={ true } type="email" pattern="email" inputmode="email" value={ username }>
								<IonLabel className='item' position="floating" color='medium'>
									{ __('Email')}
								</IonLabel>
							</IonInput>
							<FieldError className="field-error" value={__(this.state.formErrors.username)} />
						</IonItem>
						<IonItem className='password-ion-item'>
							<IonIcon className='login-icon' slot='start' size="small" icon={lockClosed}/>
							{/* <IonLabel className={isPlatform('android') ? 'android-password-label' : 'password-label'} position="floating" color='medium'>
								{ __('Password')}
							</IonLabel> */}
							<PasswordInput __={__} page='login' onIonChange={(e) => this.handleInput('password', e.target.value)} value={ password }/>
							<FieldError className="field-error" value={__(this.state.formErrors.password)} />
						</IonItem>
						<IonButton expand="block" color="primary" className="default-button login-button" onClick={() => this.handleLogin()}>{ __('Login')}</IonButton>
						<IonButton className="text-link forgot-pass" color='medium' fill="clear" size="small" onClick={() => forwardTo('/reset-password')}>{ __('forgot password?')}</IonButton>
						<IonButton expand="block" fill='outline' color="primary" className="default-button white-button" onClick={() => forwardTo('/register')}>{ __('Register')}</IonButton>
					</div>
				</Layout>
			</Loading>
		)
	}
}

const stateToProps = state => {
	const { auth } = state.profile
	return {
		auth
	}
}

export default connect(stateToProps)(withTranslation(Login))
