import React from 'react'
import { withRouter } from 'react-router'
import Accrue from './accrue'
import Redeem from './redeem'
import Layout from '../../components/layout'
import { withTranslation } from '../../lib/translate'
import { getTransactionHistory } from '../../store/actions'
import './index.css'
import { connect } from 'react-redux'
import Loading from '../../components/spinner'
import SwipableTabs from '../../components/swipeableTabs'

class History extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {
		this.props.dispatch(getTransactionHistory())
	}

	render() {
		const { __, transactionHistory } = this.props
		const accrue = transactionHistory.filter(i => i.stamp_power >= 0)
		const redeam = transactionHistory.filter(i => i.stamp_power < 0)

		return (
			<Loading>
				<Layout headerTitle={ __('History')} noPadding={ true }>
					<SwipableTabs
						tabs={[
							{ label: __('Accrue'), tabContent: <Accrue transactionHistory={ accrue } /> },
							{ label: __('Redeem'), tabContent: <Redeem transactionHistory={ redeam } /> }
						]}
					/>
				</Layout>
			</Loading>
		)
	}
}

const stateToProps = state => {
	return {
		transactionHistory: state.orders.history || []
	}
}

export default withRouter(withTranslation(connect(stateToProps)(History)))
