import React from 'react'
import { QRCodeCanvas } from 'qrcode.react';
import { withTranslation } from '../../lib/translate'
import ValidateButton from '../../components/validateButton'
import { isDefined, validateProfileData } from '../../lib/utils'
import appConfig from '../../appConfig'
import { SmallText } from '../../components/common'


const Scan = ({ __, qr_code, profile }) => {
	const valid = validateProfileData(profile).isValid
	return (
		<div className="loyalty-content scan">
			<h2>{ __('Scan For Loyalty') }</h2>
			{isDefined(qr_code) ?
				<div className="qr-holder">
					<QRCodeCanvas value={ qr_code } size={ 200 } />
				</div>
				:
				<div className='noQrCode'><h5>{ __('NO QR CODE') }</h5></div>
			}
			<ValidateButton />
			{!valid && appConfig.appType.hasEmailValidationEnabled ?
				<div className="verified-content">
					<SmallText color='grey'>{__('You can earn, but not redeem points until your account is verified')}</SmallText>
				</div> : null
			}
		</div>
	)
}

export default withTranslation(Scan)
