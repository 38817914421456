import { GET_TRANSACTION_HISTORY, SET_ORDERS_PROP } from './constants'
import { take, call, put } from 'redux-saga/effects'
import { loading } from '../common/sagas'
import api from '../../lib/api'

/* transaction history Saga */
export const getTransactionHistoryFlow = function* () {
	while (true) {
		yield take(GET_TRANSACTION_HISTORY)
		yield call(loading, function *() {
			const history = yield call(api.getHistory)
			yield put({ type: SET_ORDERS_PROP, key: 'history', value: history })
		})
	}
}
