import React, { Component } from 'react'
import { IonSegment, IonContent, IonSegmentButton, IonSlides } from '@ionic/react'
import './index.css'
import { isDefined } from '../../lib/utils'

class SwipableTabs extends Component {
	constructor(props){
		super(props)
		const { defaultTab } = this.props
		this.state = {
			selectedTab: defaultTab || 0
		}
	}

	componentDidMount() {
		this.segment = document.querySelector('ion-segment')
		this.slides = document.querySelector('ion-slides')

		this.segment.addEventListener('ionChange', ev => this.onSegmentChange(ev))
		this.slides.addEventListener('ionSlideDidChange', ev => this.onSlideDidChange(ev))
	}

	componentDidUpdate(prevProps) {
		if (this.props.history && prevProps.history.state !== this.props.history.state) {
			// fix: redirect to the scan tab if user is located in 'loyalty' page but on the first tab
			// and that he clicks on scan icon in header
			if (this.props.history.state.tab === 'scan') {
				this.segmentChanged(1)
			}
		}
	}

	segmentChanged = value => {
		this.setState({ selectedTab: value })
	}

	// On Segment change slide to the matching slide
	onSegmentChange = ev => {
		this.slideTo(ev.detail.value)
	}

	slideTo = index => {
		this.slides.slideTo(index)
	}

	// On Slide change update segment to the matching value
	onSlideDidChange = async () => {
		const index = await this.slides.getActiveIndex()
		this.clickSegment(index)
	}

	clickSegment = index => {
		this.segment.value = index
	}

	render() {
		const { selectedTab } = this.state
		const tabs = this.props.tabs || []
		return (
			<div className="tabs">
				<div className="segment-holder">
					<IonSegment onIonChange={ e => this.segmentChanged(e.detail.value)} value={ selectedTab } mode='md'>
						{ tabs.map((tab, index) => {
							const { label, icon } = tab
							return (
								<IonSegmentButton key={ index + '_segment' } value={ index }>
									{ isDefined(icon) ? <ion-icon name={ icon }></ion-icon> : null }
									<ion-label>{ label || '' }</ion-label>
								</IonSegmentButton>
							)
						})}
					</IonSegment>
				</div>
				<IonSlides options={{ initialSlide: selectedTab }}>
					{ tabs.map((tab, index) => {
						return (
							<ion-slide key={ index + 'slide' } class={ 'slide-' + (index + 1) }>
								<IonContent>
									{ tab.tabContent || null }
								</IonContent>
							</ion-slide>
						)
					})}
				</IonSlides>
			</div>
		)
	}
}

export default SwipableTabs
