import React from 'react'
import { connect } from 'react-redux'

// dummy catalog, should be imported from json
const catalog = {
	en: {},
	es: {},
	it: {},
	de: {}
}

const __ = (key, lang = null) => {
	return catalog[lang] && catalog[lang][key] ? catalog[lang][key] : key
}

const getUserLang = user => {
	return user && user.lang ? user.lang : 'en'
}

export const withTranslation = (WrappedComponent) => {
	class Translated extends React.Component {
		constructor(props) {
			super(props)
			this.__ = this.__.bind(this)
		}
		__(key){
			const { lang } = this.props
			return __(key, lang)
		}
		render(){
			return <WrappedComponent __={ this.__ } { ...this.props }/>
		}
	}

	const stateToProps = state => {
		const { auth } = state.profile
		return {
			lang: getUserLang(auth.user)
		}
	}

	return connect(stateToProps)(Translated)
}
