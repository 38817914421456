import React from 'react'
import { IonContent, useIonViewDidEnter, useIonViewDidLeave, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react'

import appConfig from '../../appConfig'
import Header from '../../components/header'
import { isDefined } from '../../lib/utils'

import './index.css'

const defaultHeaderTitle = appConfig.general.clientName

const Layout = ({ children, headerTitle, hideSecondToolbar, hideBack, color, poster, blank, noPadding, contentClassName }) => {
	useIonViewWillEnter(() => {
		// eslint-disable-next-line no-console
		console.log('1. WillEnter event fired')
	})
	useIonViewDidEnter(() => {
		// eslint-disable-next-line no-console
		console.log('2. DidEnter event fired')
	})
	useIonViewWillLeave((a,b,c) => {
		// eslint-disable-next-line no-console
		console.log('3. WillLeave event fired', a, b, c)
	})
	useIonViewDidLeave((a,b,c) => {
		// eslint-disable-next-line no-console
		console.log('4. DidLeave event fired', a, b, c)
	})
	return (
		<>
			{ blank ? null :
				<>
					{ hideSecondToolbar ? null :
						<Header title={ headerTitle || defaultHeaderTitle } hideBack={ hideBack }/>
					}
				</>
			}
			<IonContent color={ isDefined(color) ? color : 'light' } className={ contentClassName || '' }>
				{ poster ? <div className={ 'poster ' + poster }/> : null }
				<div className={ noPadding ? 'no-padding' : 'ion-padding' }>{ children }</div>
			</IonContent>
		</>
	)
}

export default Layout
