import { take, call, put } from 'redux-saga/effects'
import api from '../../lib/api'
import { loading } from '../common/sagas'
import { SET_RESTAURANT_PROP, GET_RESTAURANTS, GET_REWARDS } from './constants'


/* get Restaurants Saga */
export const getRestaurantsFlow = function* () {
	while (true) {
		yield take(GET_RESTAURANTS)
		//get restaurants
		yield call(loading, function *() {
			const restaurants = yield call(api.getRestaurants)
			yield put({ type: SET_RESTAURANT_PROP, key: 'restaurants', value: restaurants })
		})
	}
}

/* get Restaurants Saga */
export const getRewardsFlow = function* () {
	while (true) {
		yield take(GET_REWARDS)
		//get restaurants
		yield call(loading, function *() {
			let rewards = yield call(api.getRewards)
			rewards = rewards.sort((a, b) => {
				if (a.stamps_required < b.stamps_required) {return -1}
				if (a.stamps_required > b.stamps_required ) {return 1}
				return 0
			})
			yield put({ type: SET_RESTAURANT_PROP, key: 'rewards', value: rewards })
		})
	}
}
