import { LOGIN_REQUEST, LOGOUT, REGISTER_REQUEST, GET_PROFILE, SET_PROFILE_PROP, UPDATE_PROFILE, RESTORE_AUTH, SET_MODAL, RESET_PASSWORD, VALIDATE_EMAIL, CLEAR_REGISTER_FORM, REMOVE_PROFILE } from './constants'
import { SET_COMMON_MODAL } from '../constants'
import { loading } from '../common/sagas'
import { take, call, put, select, cancel } from 'redux-saga/effects'
import api from '../../lib/api'
import { forwardTo, getDefaultRoute, isDefined, validateProfileData } from '../../lib/utils'
import asyncStorage from '../../lib/asyncStorage'
import { showToast } from '../actions'
import { saveFcmToken } from '../common/sagas'
import appConfig from '../../appConfig'
// import delay from '@redux-saga/delay-p'

const authRoutes = {
	protectedLanding: getDefaultRoute(true).path,
	unprotectedLanding: getDefaultRoute().path
}

/**
 * Effect to handle authorization
 * @param { string } username The username of the user
 * @param { string } password The password of the user
 * @param { object } options Options
 * @param { boolean } options.isRegistering Is this a register request?
 */
export const authorize = function* (data) {
	const authStore = yield select(state => state.profile.auth)
	if (authStore.loggedIn){ return authStore }
	let returnValue = null
	if (data.isRegistering) {
		data.isRegistering = undefined
		const registerData = yield call(api.register, data )
		returnValue = registerData
	} else {
		data.isRegistering = undefined
		const { username, password } = data
		const loginData = yield call(api.login, username, password)
		returnValue = loginData
	}

	return returnValue
}

/* Login saga */
export const loginFlow = function* () {
	while (true) {
		const { username, password } = yield take(LOGIN_REQUEST)
		yield call(loading, function *() {
			const response = yield call(authorize, { username, password, isRegistering: false })
			const { token, profile } = response.data

			yield call(asyncStorage.setItem, 'token', token)
			yield call(asyncStorage.setItem, 'profile', JSON.stringify(profile))

			yield put({ type: SET_PROFILE_PROP, key: 'auth', value: { loggedIn: true, token } })
			yield put({ type: SET_PROFILE_PROP, key: 'profile', value: profile })
			yield call(saveFcmToken)
			forwardTo(authRoutes.protectedLanding)
		})
		yield call(postLogingFlow)
	}
}

export const restoreAuthFlow = function* () {
	while (true) {
		yield take(RESTORE_AUTH)
		const token = yield call(asyncStorage.getItem, 'token')
		const profile = yield call(asyncStorage.getItem, 'profile')

		if (isDefined(token) && isDefined(profile)) {
			yield put({ type: SET_PROFILE_PROP, key: 'auth', value: { loggedIn: true, token } })
			yield put({ type: SET_PROFILE_PROP, key: 'profile', value: JSON.parse(profile) })
			yield call(saveFcmToken)
			yield call(postLogingFlow)
		}
	}
}

/* Register saga */
export const registerFlow = function* () {
	while (true) {
		yield take(REGISTER_REQUEST)
		yield call(loading, function *() {
			const profile = yield select(state => state.profile)
			const { registerFormData } = profile
			const data = {
				...registerFormData
			}
			yield put({ type: CLEAR_REGISTER_FORM })
			yield call(authorize, { ...data, isRegistering: true })
			//auto login after register
			const { email, password } = data
			yield put({ type: LOGIN_REQUEST, username: email, password: password })
			// yield put({ type: SET_MODAL, modal: 'isRegisterModalOpen', value: true })
		})
	}
}

// const periodicalSaga = function* () {
// 	while (true) {
// 		yield put({ type: GET_PROFILE, skipLoading: true })
// 		yield delay(appConfig.general.periodForSaga * 1000)
// 	}
// }

// const startPeriodicalSaga = function* () {
// 	const timer = yield fork(periodicalSaga)
// 	const store = yield select()
// 	if (isDefined(store.profile.timer)) {
// 		yield cancel(store.profile.timer)
// 	}
// 	yield put({ type: SET_PROFILE_PROP, key: 'timer', value: timer })
// }

const stopPeriodicalSaga = function* () {
	const store = yield select()
	if (isDefined(store.profile.timer)) {
		yield cancel(store.profile.timer)
	}
	yield put({ type: SET_PROFILE_PROP, key: 'timer', value: null })
}

const postLogingFlow = function* () {
	// yield call(startPeriodicalSaga)
	yield call(showValidationPopUp)
}

/* Log out saga */
export const logoutFlow = function* () {
	while (true) {
		yield take(LOGOUT)
		yield call(asyncStorage.removeItem, 'token')
		yield call(asyncStorage.removeItem, 'profile')
		yield put({ type: SET_PROFILE_PROP, key: 'auth', value: {} })
		yield put({ type: SET_PROFILE_PROP, key: 'profile', value: {} })
		//stop periodical profile fetching
		yield call(stopPeriodicalSaga)
		forwardTo(authRoutes.unprotectedLanding)
	}
}

/* get Profile saga */
export const getProfileFlow = function* () {
	while (true) {
		const action = yield take(GET_PROFILE)
		const saga = function *(){
			const response = yield call(api.getProfile)
			yield put({ type: SET_PROFILE_PROP, key: 'profile', value: response.data.profile })
			yield call(asyncStorage.setItem, 'profile', JSON.stringify(response.data.profile ))
			yield call(showValidationPopUp)
		}
		if (action.skipLoading) {
			yield call(saga)
		} else {
			yield call(saga)
		}
	}
}

/* update Profile saga */
export const updateProfileFlow = function* () {
	while (true) {
		const action = yield take(UPDATE_PROFILE)
		yield call(loading, function *() {
			const response = yield call(api.updateProfile, action.data)
			const profile = response.data.profile
			yield put({ type: SET_PROFILE_PROP, key: 'profile', value: profile, merge: true })
			yield call(asyncStorage.setItem, 'profile', JSON.stringify(profile))
			if (!isDefined(action.skipAlert) || !action.skipAlert) {
				yield put({ type: SET_MODAL, modal: 'isProfileModalOpen', value: true })
			}
		})
	}
}

/* reset Password saga */
export const resetPasswordFlow = function* () {
	while (true) {
		const action = yield take(RESET_PASSWORD)
		yield call(api.resetPassword, action.email)
		yield put({ type: SET_MODAL, modal: 'isResetPasswordModalOpen', value: true })
	}
}

/* validte Email saga */
export const validateEmailFlow = function* () {
	while (true) {
		const action = yield take(VALIDATE_EMAIL)
		const validate_profile = action.validate_profile
		yield put({ type: UPDATE_PROFILE, validate_profile, skipAlert: true })
		const result = yield call(api.validateEmail)
		yield put(showToast(result.data.data.success, 'success'))
	}
}

/* show validtion PopUp Flow saga */
export const showValidationPopUp = function* () {
	const profile = yield select(state => state.profile.profile)
	const valid = validateProfileData(profile).isValid
	if (appConfig.appType.hasEmailValidationEnabled && valid && isDefined(profile.is_verification_pop_up_shown) && !profile.is_verification_pop_up_shown) {
		yield put({ type: SET_COMMON_MODAL, modal: 'isValidationModalOpen', value: true })
	}
}

export const removeProfileFlow = function* () {
	while (true) {
	  const action = yield take(REMOVE_PROFILE);
	  yield call(loading, function* () {
		yield put({ type: LOGOUT });
		let response = yield call(api.removeProfile, { token: action.token });
		response = response.data;
		if (response.error) {
		  yield put(showToast(yield call(translateSaga, response.error), 'danger'));
		} else if (response.message) {
		  yield put(showToast(yield call(translateSaga, response.message), 'success'));
		}
	  });
	}
  };