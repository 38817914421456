import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IonToast } from '@ionic/react'
import { isDefined } from '../../lib/utils'
import { resetToast } from '../../store/actions'

class Toast extends Component {
	constructor(props){
		super(props)
		this.state = {
			isConnectedEstablished: false
		}
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.isConnectedToNetwork && this.props.isConnectedToNetwork) {
			//show connection established toast only
			//if we have scenario: offline -> online
			this.setState({ isConnectedEstablished: true })
		}
	}

	render() {
		const { message, toastType, resetToast, isConnectedToNetwork } = this.props
		const { isConnectedEstablished } = this.state
		const displayToast = isDefined(message) && message !== ''

		if (isConnectedEstablished) {
			return (
				<IonToast isOpen={ true } color='success' message='Connection established' duration={ 5000 } showCloseButton
					onDidDismiss={ () => this.setState({ isConnectedEstablished: false }) }
				/>
			)
		}
		//show connection toast
		if (isDefined(isConnectedToNetwork) && !isConnectedToNetwork) {
			return <IonToast isOpen={ true } color='danger' message="Connection lost!" />
		} else {
			return <IonToast isOpen={ displayToast } onDidDismiss={ resetToast } color={ toastType } message={ message } duration={ 5000 } />
		}
	}
}

const stateToProps = state => {
	const { common } = state
	const { message, toastType } = common.toast

	return {
		message: isDefined(message) && message !== '' ? message : '',
		toastType: isDefined(toastType) && toastType !== '' ? toastType : 'warning',
		isConnectedToNetwork: common.isConnectedToNetwork
	}
}

const dispatchToProps = dispatch => {
	return {
		resetToast: () => dispatch(resetToast())
	}
}

export default connect(stateToProps, dispatchToProps)(Toast)
