import { take, call, put, select } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { isDefined } from '../../lib/utils'
import api from '../../lib/api'
import { LOADING, REQUEST_ERROR, SEND_FIREBASE_TOKEN, INIT, SET_COMMON_PROP, GET_SOCIALS, SEND_FEEDBACK, SET_COMMON_MODAL, GET_TERMS, GET_PRIVACY_POLICY, INIT_FIREBASE } from './constants'
import { UPDATE_PROFILE } from '../profile/constants'
import { GET_RESTAURANTS } from '../restaurants/constants'
import { Capacitor } from '@capacitor/core'
import { restoreAuth } from '../profile/actions'
import { SplashScreen } from '@capacitor/splash-screen'
import { PushNotifications } from '@capacitor/push-notifications'


export const loading = function* (fn = null) {
	try {
		if (!isDefined(fn)) { return }
		yield put({ type: LOADING, loading: true })
		if (fn){
			yield call(fn)
		}
	} finally {
		yield put({ type: LOADING, loading: false })
	}
}

/**
 * send firebase token saga
 */
// TO DO: CHECK THIS (AP)
export const sendFirebaseToken = function* () {
	while (true) {
		const request = yield take(SEND_FIREBASE_TOKEN)
		yield call(loading, function *() {
			try {
				yield call(api.sendFirebaseToken, request.args)
			} catch (error) {
				yield put({ type: REQUEST_ERROR, error: error.message })
			}
		})
	}
}

/* On init app */
export const initSaga = function* () {
	while (true) {
		yield take(INIT)
		yield put(restoreAuth())
		const authStore = yield select(state => state.profile.auth)
		if (authStore && authStore.loggedIn){
			yield call(api.createAxiosInstance, authStore.token)
		} else {
			yield call(api.createAxiosInstance)
		}
		SplashScreen.hide()

		//add firebase listeners
		yield put({ type: INIT_FIREBASE })
		//add app version
		const appVersion = yield call(api.getAppVersion)
		yield put({ type: SET_COMMON_PROP, key: 'appVersion', value: appVersion })

		yield put({ type: GET_RESTAURANTS })
	}
}

/* social Saga */
export const socialSagaFlow = function* () {
	while (true) {
		yield take(GET_SOCIALS)
		//get socials
		yield call(loading, function *() {
			const social = yield call(api.getSocialLinks)
			yield put({ type: SET_COMMON_PROP, key: 'social', value: social })
		})
	}
}

/* send Feedback Saga */
export const sendFeedbackSaga = function* () {
	while (true) {
		const action = yield take(SEND_FEEDBACK)
		yield call(loading, function *() {
			const { food, service, commentService,commentTech, selectedRestaurant, customerService } = action.data
			const customerServicePayload = {
				selected_restaurant: selectedRestaurant,
				feedback_type: 'app customer_service',
				food_score: food,
				service_score: service,
				feedback_response: commentService
			}
			const techServicePayload = {
				selected_restaurant: selectedRestaurant,
				feedback_type: 'app tech_support',
				feedback_response: commentTech
			}
			//send feedback
			if (customerService){
				yield call(api.sendFeedback, customerServicePayload )
			} else {
				yield call(api.sendFeedback, techServicePayload )
			}
			yield put({ type: SET_COMMON_MODAL, modal: 'isFeedbackModalOpen', value: true })
		})
	}
}

/* terms Saga */
export const getTermsFlow = function* () {
	while (true) {
		yield take(GET_TERMS)
		yield call(loading, function *() {
			const terms = yield call(api.getTerms)
			yield put({ type: SET_COMMON_PROP, key: 'terms', value: terms })
		})
	}
}

export const getPrivacyPolicyFlow = function* () {
	while (true) {
		yield take(GET_PRIVACY_POLICY)
		yield call(loading, function *() {
			const privacyPolicy = yield call(api.getPrivacyPolicy)
			yield put({ type: SET_COMMON_PROP, key: 'privacyPolicy', value: privacyPolicy })
		})
	}
}

/* firebase Saga */
export const firebaseFlow = function* () {
	while (true) {
		yield take(INIT_FIREBASE)
		if (Capacitor.getPlatform() !== 'web') {
			// Register with Apple / Google to receive push via APNS/FCM
			PushNotifications.register()

			const firebaseChannel = eventChannel(emitter => {
				PushNotifications.addListener('registration', token => {
					emitter({
						type: 'registration',
						value: token.value
					})})

				// Some issue with your setup and push will not work
				PushNotifications.addListener('registrationError', error => { emitter({
					type: 'registrationError',
					value: error
				})})

				// Show us the notification payload if the app is open on our device
				PushNotifications.addListener('pushNotificationReceived', notification => { emitter({
					type: 'pushNotificationReceived',
					value: notification
				})})

				// Method called when tapping on a notification
				PushNotifications.addListener('pushNotificationActionPerformed', notification => { emitter({
					type: 'pushNotificationActionPerformed',
					value: notification
				})})
			})

			while (true) {
				const firebaseMessage = yield take(firebaseChannel)
				// eslint-disable-next-line no-console
				console.log('firebase message', firebaseMessage)
				switch (firebaseMessage.type) {
				case 'registration': {
					yield put({ type: SET_COMMON_PROP, key: 'deviceFcmToken', value: firebaseMessage.value })
					break
				}
				default:
				}
			}
		}
	}
}

export const saveFcmToken = function* () {
	const profile = yield select(store => store.profile.profile)
	const deviceFcmToken = yield select(store => store.common.deviceFcmToken)

	if (isDefined(profile) && isDefined(deviceFcmToken) && profile.fcm_token !== deviceFcmToken) {
		//save new fcm token
		yield put({ type: UPDATE_PROFILE, data: { fcm_token: deviceFcmToken }, skipAlert: true })
	}
}
