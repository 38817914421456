import React from 'react'
import { IonText } from '@ionic/react'

export const FieldError = props => {
	const { value, className } = props
	let err = value === '' ? null : <div className={ className ? className : null }>{ value }</div>
	return err
}

export const SmallText = (props) => {
	const { tag, color, className, children, ...rest } = props
	const Tag = tag || 'span'
	return (
		<IonText color={ color || null }><Tag className={ 'small-text' + (className ? ' ' + className : '')} { ...rest }>{ children }</Tag></IonText>
	)
}
