import React from 'react'
import { withRouter } from 'react-router'
import { IonGrid, IonRow, IonCol, IonIcon } from '@ionic/react'
import { helpCircleOutline } from 'ionicons/icons'
import { connect } from 'react-redux'
import { withTranslation } from '../../lib/translate'
import { validateProfileData } from '../../lib/utils'
import { SmallText } from '../common'
import { setModal } from '../../store/actions'
import appConfig from '../../appConfig'
import './index.css'

const check = require('../../assets/images/VibeJuice_Check.svg')

const ValidateButton = ({ __, dispatch, profile }) => {
	const valid = validateProfileData(profile).isValid
	if (!appConfig.appType.hasEmailValidationEnabled){
		return null
	}
	return (
		<>
			{
				!valid ?
					<>
						<IonGrid className="validate-button-wrapper bottom">
							<IonRow onClick={() => dispatch(setModal('isVerfiedModalOpen', true))}>
								<IonCol className="mixed-right"><SmallText>{__('Not Verified')}</SmallText></IonCol>
								<IonCol className="mixed-left"><div style={{ display: 'flex' }}><IonIcon slot="icon-only" icon={helpCircleOutline} /></div></IonCol>
							</IonRow>
						</IonGrid>
					</>
					:
					<IonGrid className="validate-button-wrapper">
						<IonRow>
							<IonCol className="mixed-right"><div style={{ display: 'flex' }}><IonIcon slot="icon-only" icon={check} /></div></IonCol>
							<IonCol className="verified-right"><SmallText color='secondary'>{__('Account Verified')}</SmallText></IonCol>
						</IonRow>
					</IonGrid>
			}
		</>
	)
}

const stateToProps = store => {
	const { isVerfiedModalOpen, profile } = store.profile
	return {
		isVerfiedModalOpen,
		profile
	}
}

export default connect(stateToProps)(withRouter(withTranslation(ValidateButton)))
