import React from 'react'
import { IonButton, IonInput, IonToggle, IonItem, IonLabel, IonNote, IonList, IonAlert } from '@ionic/react'
import { connect } from 'react-redux'

import appConfig from '../../appConfig'
import Layout from '../../components/layout'
import PasswordInput from '../../components/passwordInput'
import { forwardTo, getDefaultRoute } from '../../lib/utils'
import { registerRequest, setModal, setRegisterForm } from '../../store/actions'
import { withTranslation } from '../../lib/translate'
import { FieldError } from '../../components/common'
import { validateForm } from '../../lib/utils'
import Loading from '../../components/spinner'
import './index.css'

class Register extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			formErrors: {}
		}
		this.handleInput = this.handleInput.bind(this)
		this.handleRegister = this.handleRegister.bind(this)
		this.navToTerms = this.navToTerms.bind(this)
		this.formConfig = {
			email: { type: 'email', required: true },
			password: { type: 'password', required: true },
			accepted_terms_and_conditions: { type: 'toggle', toggle: true}
		}
	}

	handleInput (key, val) {
		const { dispatch } = this.props
		dispatch(setRegisterForm(key, val))
		if (key === 'accepted_terms_and_conditions'){
			let formErrors = { ...this.state.formErrors }
			formErrors.accepted_terms_and_conditions = undefined
			this.setState({ formErrors })
		}

	}

	handleRegister () {
		const { registerFormData } = this.props
		let formErrors = validateForm(this.formConfig, registerFormData)
		this.setState({ formErrors })
		if (Object.keys(formErrors).length === 0) {
			this.props.dispatch(registerRequest())
		}
	}

	componentDidMount() {
		const { loggedIn } = this.props.auth
		if (loggedIn){
			const defaultRoute = getDefaultRoute()
			forwardTo(defaultRoute.path)
		}
	}


	returnToLogin = (history) => history.goBack()

	navToTerms = () => forwardTo('/terms')

	render (){
		const { __, isRegisterModalOpen, registerFormData, history } = this.props
		const email = registerFormData.email
		const password = registerFormData.password
		const accepted_terms_and_conditions = registerFormData.accepted_terms_and_conditions
		const is_subscribed = registerFormData.is_subscribed

		return (
			<Loading transparent>
				<Layout headerTitle="Register">
					<h3>{ __('To register please enter your email address and a password') }</h3>
					<div className="box-holder">
						<IonList>
							<IonItem>
								<IonLabel position="floating">{ __('Email') }</IonLabel>
								<IonInput onIonChange={ e => this.handleInput('email', e.target.value) } required={ true } type="email" pattern="email" inputmode="email" value={ email }></IonInput>
							</IonItem>
							<FieldError className="field-error" value={ __(this.state.formErrors.email) } />
							<IonItem>
								<IonLabel position="floating">{ __('Password') }</IonLabel>
								<PasswordInput onIonChange={ e => this.handleInput('password', e.target.value) } value={ password }/>
								<FieldError className="field-error" value={ __(this.state.formErrors.password) } />
							</IonItem>
						</IonList>
					</div>
					<h3 onClick={ this.navToTerms } className="title2">{ __('View our terms and conditions of use') }</h3>
					<div className="box-holder">
						<IonList>
							<IonItem>
								<div tabIndex="-1"></div>
								<div className="toggle">
									<IonLabel>
										<h2>{ __('Accept T&Cs') }</h2>
										<IonLabel className="ion-text-wrap">
											<IonNote>{ __('By signing up you agree to our terms and conditions of service') }</IonNote>
										</IonLabel>
									</IonLabel>
									<FieldError className="field-error" value={ __(this.state.formErrors.accepted_terms_and_conditions) } />
								</div>
								<IonToggle color="primary" slot="end" checked={accepted_terms_and_conditions} onIonChange={ e => this.handleInput('accepted_terms_and_conditions', e.detail.checked)}/>
							</IonItem>
							<IonItem line="none">
								<div tabIndex="-1"></div>
								<IonLabel>
									<h2>{ __('Email Opt in') }</h2>
									<IonLabel className="ion-text-wrap">
										<IonNote>{ __('I would like to receive') + ' ' + appConfig.general.clientName + ' ' + __('updates and others via email')}</IonNote>
									</IonLabel>
								</IonLabel>
								<IonToggle color="primary" slot="end" checked={is_subscribed} onIonChange={ e => this.handleInput('is_subscribed', e.detail.checked)} />
							</IonItem>
						</IonList>
					</div>
					<div className="top-medium">
						<IonButton expand="block" color="primary" onClick={() => this.handleRegister()}>{ __('Register') }</IonButton>
						<IonButton expand="block" color="white" onClick={() => this.returnToLogin(history)}>{ __('Cancel') }</IonButton>
					</div>
				</Layout>
				<IonAlert
					isOpen={ isRegisterModalOpen }
					onDidDismiss={ () => this.props.dispatch(setModal('isRegisterModalOpen', false)) }
					header={ __('Success') }
					message={ __('Register processed. Please check your mail') }
					buttons={[
						{
							text: __('Close'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.props.dispatch(setModal(('isRegisterModalOpen', false)))
						}
					]}
				/>
			</Loading>
		)
	}
}

const stateToProps = state => {
	const { auth, isRegisterModalOpen, registerFormData } = state.profile
	return {
		auth,
		isRegisterModalOpen,
		registerFormData
	}
}

export default connect(stateToProps)(withTranslation(Register))
