import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IonList, IonItem, IonLabel, IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react'
import Layout from '../../components/layout'
import { withTranslation } from '../../lib/translate'
import './index.css'
import { isDefined, isEmptyObject, forwardTo } from '../../lib/utils'

class Dashboard extends Component {
	constructor(props){
		super(props)
		this.state = {
		}
	}

	render() {
		const { __, screenName } = this.props
		return (
			<Layout hideSecondToolbar={ true } poster="dashboard-01">
				<IonCard className="dash-card">
					<IonCardHeader>
						<IonCardTitle className="ion-text-center">{ __('Welcome Back')} { screenName }</IonCardTitle>
					</IonCardHeader>

					<IonCardContent className="dash-menu">
						<IonList>
							<IonItem className="clickable" onClick={() => forwardTo('/loyalty')}>
								<div tabIndex="-1"></div>
								<IonLabel>{ __('Loyalty')}</IonLabel>
							</IonItem>
							<IonItem className="clickable" onClick={() => forwardTo('/account')}>
								<div tabIndex="-1"></div>
								<IonLabel>{ __('My Account')}</IonLabel>
							</IonItem>
							<IonItem className="clickable" onClick={() => forwardTo('/locations')} lines="none">
								<div tabIndex="-1"></div>
								<IonLabel>{ __('Locations')}</IonLabel>
							</IonItem>
						</IonList>
					</IonCardContent>
				</IonCard>
			</Layout>
		)
	}
}

const stateToProps = state => {
	const { profile } = state.profile
	let screenName = ''
	if (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) {
		screenName = profile.first_name
	}
	return {
		screenName
	}
}

export default connect(stateToProps)(withTranslation(Dashboard))
