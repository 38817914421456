const appConfig = {
	theme: {
		nav: {
			label: 'VibeJuice'
		}
	},
	services: {
		google_maps: {
			google_api_key: 'AIzaSyAQQRE3dm_3Jk2BF0UOfQ31fjLhnpnDJQw',
			defaultLat: 0,
			defaultLng: 0
		}
	},
	api: {
		baseURL: 'https://vibe-juice.5loyalty.com',
		// baseURL: 'http://192.168.0.194:8000', //preda
		paymentURL: 'https://pay-mo.co.uk'
	},
	general: {
		clientName: 'Vibe Juice',
		appExitRoutes: ['/','/home','/dashboard'],
		periodForSaga: 3 // x seconds // e.g. fetching profile
	},
	appType: {
		hasEmailValidationEnabled: false
	},
	configS3: {
		imageNamePrefix: 'vibe_juice_profile_image_',
		bucketName: 'hybrid-apps-profile-images',
		region: 'eu-west-1',
		accessKeyId: 'AKIAIMWCI6M7IF4RTWSA',
		secretAccessKey: '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'
	},
	firebaseConfig: {
		apiKey: 'AIzaSyAQQRE3dm_3Jk2BF0UOfQ31fjLhnpnDJQw',
		authDomain: 'vibe-juice.firebaseapp.com',
		databaseURL: 'https://vibe-juice.firebaseio.com',
		projectId: 'vibe-juice',
		storageBucket: 'vibe-juice.appspot.com',
		messagingSenderId: '576103347958',
		appId: '1:576103347958:web:d54f3bb4bfc3c7ad675ed7',
		measurementId: 'G-D8P70P5ZD7'
	}
}

export default appConfig
