import React from 'react'
import { connect } from 'react-redux'
import { IonItem, IonButton, IonInput, IonAlert, IonLabel, IonList } from '@ionic/react'
import Layout from '../../components/layout'
import { FieldError } from '../../components/common'
import { resetPassword, setModal } from '../../store/actions'
import { withTranslation } from '../../lib/translate'
import { forwardTo, validateForm } from '../../lib/utils'

class ResetPassword extends React.Component {
	constructor (props) {
		super(props)
		this.state = {
			email: '',
			formErrors: {}
		}
		this.handleInput = this.handleInput.bind(this)
		this.handleResetPassword = this.handleResetPassword.bind(this)
		this.formConfig = {
			email: { type: 'email', required: true }
		}
	}

	handleResetPassword() {
		let formErrors = validateForm(this.formConfig, this.state)
		this.setState({ formErrors })
		if (Object.keys(formErrors).length === 0) {
			const { email } = this.state
			this.props.dispatch(resetPassword(email))
		}
	}

	handleInput (key, val) {
		this.setState({[key]: val })
	}

	returnToLogin = () => {
		forwardTo('/login')
	}

	render() {
		const { __, isResetPasswordModalOpen } = this.props
		const { email, formErrors } = this.state
		return (
			<>
				<Layout headerTitle={ __('Reset Password') }>
					<h3>{ __('Enter your email to receive instructions to reset your password') }</h3>
					<div className="box-holder">
						<IonList>
							<IonItem className="single-item">
								<IonLabel position="floating">{ __('Email') }</IonLabel>
								<IonInput onIonChange={(e) => this.handleInput('email', e.target.value)} clearInput required={ true } type="email" pattern="email" inputmode="email" value={ email } />
								{formErrors.email ? <FieldError className="field-error" value={ __(formErrors.email)}/> : null}
							</IonItem>
						</IonList>
					</div>
					<div className="top-medium">
						<IonButton expand="block" color="primary" onClick={()=>this.handleResetPassword()}>{ __('Submit') }</IonButton>
						<IonButton expand="block" color="white" onClick={ this.returnToLogin }>{ __('Cancel') }</IonButton>
					</div>
				</Layout>
				<IonAlert
					isOpen={ isResetPasswordModalOpen }
					onDidDismiss={() => this.props.dispatch(setModal('isResetPasswordModalOpen', false))}
					header={ __('Success') }
					message={ __('Reset processed. Please check your mail') }
					buttons={[
						{
							text: __('Close'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.props.dispatch(setModal(('isResetPasswordModalOpen', false)))
						}
					]}
				/>
			</>
		)
	}
}


const stateToProps = state => {
	const { auth, isResetPasswordModalOpen } = state.profile
	return {
		auth,
		isResetPasswordModalOpen
	}
}

export default connect(stateToProps)(withTranslation(ResetPassword))
