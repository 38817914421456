import { REQUEST_ERROR, CLEAR_ERROR, LOADING, ERROR, SET_COMMON_PROP, SHOW_TOAST, RESET_TOAST, SET_COMMON_MODAL, CHANGE_CONNECTIONS_STATUS } from './constants'

// The initial application state
let initialState = {
	loading: 0,
	error: {
		message: '',
		errors: {},
		errorStatusCode: null
	},
	toast: {},
	currentlySending: 0,
	appVersion: '',
	social: {},
	isFeedbackModalOpen: false,
	terms: null,
	privacyPolicy: null,
	deviceFcmToken: null,
	isConnectedToNetwork: true,
	isValidationModalOpen: false
}

function reducer(state = initialState, action) {
	switch (action.type) {
	case REQUEST_ERROR:
		return { ...state, error: action.error }
	case CLEAR_ERROR:
		return { ...state, error: initialState.errors }
	case LOADING:
		return { ...state, loading: action.loading ? ++state.loading : state.loading === 0 ? state.loading : --state.loading }
	case ERROR:
		return { ...state, error: action.error }
	case SHOW_TOAST: {
		const toast = { message: action.message || '', toastType: action.toastType }
		return { ...state, toast }
	}
	case RESET_TOAST: {
		const toast = { message: '', toastType: null }
		return { ...state, toast }
	}
	case SET_COMMON_MODAL:
		return { ...state, [action.modal]: action.value }
	case CHANGE_CONNECTIONS_STATUS:
		return { ...state, isConnectedToNetwork: action.status }
	case SET_COMMON_PROP:
		return { ...state, [action.key]: action.value }
	default:
		return state
	}
}

export default reducer
