import React from 'react'
import { IonIcon, IonInput, IonButton, isPlatform, IonLabel } from '@ionic/react'
import { eye, eyeOff } from 'ionicons/icons'
import './index.css'

class PasswordInput extends React.Component {
	state = {
		value: this.props.value || '',
		show: false
	}

	togglePass = (show) => {
		this.setState({ show })
	}

	onChange = e => {
		const { onIonChange } = this.props
		this.setState({ value: e.target.value })
		if (onIonChange){
			onIonChange(e)
		}
	}

	render (){
		const { show, value } = this.state
		const { __, page } = this.props
		return (
			<IonInput className={isPlatform('android') && page && page === 'login' ? 'android-okx-password' : 'okx-password'} type={ show ? 'text' : 'password' } pattern="password" value={ value } onIonChange={ this.onChange }>
				{
					page && page === 'login'
						?
						<IonLabel className={isPlatform('android') && page && page === 'login' ? 'android-password-label' : 'password-label'} position="floating" color='medium'>
							{ __('Password')}
						</IonLabel>
						:
						null
				}
				<IonButton color="medium" disabled={ value === '' } fill="clear" size="small" slot="end" onTouchStart={() => this.togglePass(true)} onTouchEnd={() => this.togglePass(false)} onMouseDown={() => this.togglePass(true)} onMouseUp={() => this.togglePass(false)}>
					<IonIcon slot="icon-only" icon={ show ? eyeOff : eye }/>
				</IonButton>
			</IonInput>
		)
	}
}

export default PasswordInput
